<template>
  <div class="column is-12">
    <div class="columns mt-2">
      <div class="column is-full-mobile is-one-quarter-tablet is-3-desktop">
        <b-field class="text-left" label="Agregar filtro">
          <b-select
            placeholder="Selecciona un filtro"
            v-model="filtroActivo"
            expanded
            :disabled="disposiciones.length == 0"
          >
            <option
              v-for="(filtro, key, index) in filtros"
              :value="key"
              :key="index"
            >
              {{ filtro }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div
        class="column is-one-quarter-tablet is-3-desktop"
        v-if="filtroActivo && filtroActivo != 'todas'"
      >
        <b-field class="text-left" :label="nombreFiltro">
          <b-autocomplete
            v-model="opcionActiva"
            placeholder="Buscar..."
            class="capitalize"
            :keep-first="true"
            :field="field"
            :open-on-focus="true"
            :data="filteredOpciones"
            @select="cargarDisposiciones"
          />
        </b-field>
      </div>
    </div>
    <Tags :tagsFiltros="tagsFiltros" @removeFilter="removeFilter" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tags from "@/components/generals/TagsFiltros";

export default {
  name: "FiltrosDisposicion",
  props: {
    disposiciones: Array,
    activeTab: Number,
    tipo_disposicion: String,
    pagina: Number,
    checkbox: Boolean,
    lineaId: {
      type: Number,
      required: false
    }
  },
  components: {
    Tags
  },
  data() {
    return {
      filtroActivo: null,
      filtros: {
        todas: "Todas",
        credito_status_id: "Estatus",
        ejecutivo_id: "Ejecutivo",
        acreditado_id: "Acreditado",
        id: "Folio",
        productos_financieros_id: "Producto Financiero"
      },
      tagsFiltros: [],
      opcionActiva: "",
      opciones: [],
      sinFiltro: true,
      field: null
    };
  },
  methods: {
    /*
      Se agrega la opción al filtro y se llama a la API con el nuevo filtro
      @opcion(Object): nuevo filtro seleccionado
    */
    cargarDisposiciones(opcion) {
      if (opcion) {
        let filtro = this.setFiltro(opcion);
        let repetido = this.checkRepetido(filtro);
        if (!repetido) {
          this.tagsFiltros.push(filtro);
          this.filtroActivo = null;
          this.$store.dispatch("disposicion/getDisposiciones", {
            tipo: this.tipo_disposicion,
            filtro: this.tagsFiltros,
            linea_id: this.lineaId
          });
        }
      }
      this.$store.dispatch("disposicion/setFiltros", this.tagsFiltros);
    },
    /*
      @pagina(Integer): número de página para hacer request
    */
    paginacionDisposiciones(pagina) {
      this.$store
        .dispatch("disposicion/getDisposiciones", {
          tipo: this.tipo_disposicion,
          filtro: this.tagsFiltros,
          linea_id: this.lineaId,
          pagina: pagina
        })
        .then(() => {
          this.$emit("togglecheck", false);
        });
    },
    /*
      @opcion(Object): filtro a incluir
    */
    setFiltro(opcion) {
      let id = null;
      let nombre = null;
      switch (this.filtroActivo) {
        case "credito_status_id":
          id = opcion.id;
          nombre = opcion.nombre;
          break;
        case "ejecutivo_id":
        case "acreditado_id":
          id = opcion.personas_id;
          nombre = opcion.alias;
          break;
        case "id":
          id = opcion.id;
          nombre = opcion.id;
          break;
        case "productos_financieros_id":
          id = opcion.id;
          nombre = opcion.alias;
          break;
      }
      return {
        filtro_id: this.filtroActivo,
        filtro: this.filtros[this.filtroActivo],
        id: id,
        nombre: nombre
      };
    },
    /*
      @filtro(Object): comprueba si el filtro ya se encuentra en uso
    */
    checkRepetido(filtro) {
      let repetido = this.tagsFiltros.filter(filtro_temp => {
        return (
          Object.entries(filtro_temp).toString() ===
          Object.entries(filtro).toString()
        );
      });
      if (repetido.length > 0) {
        this.filtroActivo = null;
        this.notification(
          "warning",
          "Filtro en uso",
          "El filtro que quieres agregar ya se encuentra en uso"
        );
        return true;
      }
      return false;
    },
    /*
      Elimina el filtro por su indice en el arreglo
      @index(Integer): indice del arreglo
    */
    removeFilter(index) {
      this.tagsFiltros.splice(index, 1);
      this.$store.dispatch("disposicion/getDisposiciones", {
        tipo: this.tipo_disposicion,
        filtro: this.tagsFiltros
      });
      this.$store.dispatch("disposicion/setFiltros", this.tagsFiltros);
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getCreditoStatus",
      "getEjecutivos",
      "getClientes",
      "getProductosFinancieros"
    ]),
    nombreFiltro() {
      for (var [key, value] of Object.entries(this.filtros)) {
        if (key == this.filtroActivo) {
          return value;
        }
      }
      return "";
    },
    filteredOpciones() {
      switch (this.filtroActivo) {
        case "credito_status_id":
          return this.opciones.filter(
            option =>
              option.nombre
                .toLowerCase()
                .indexOf(this.opcionActiva.toLowerCase()) >= 0
          );
        case "ejecutivo_id":
        case "productos_financieros_id":
        case "acreditado_id":
          return this.opciones.filter(
            option =>
              option.alias
                .toString()
                .toLowerCase()
                .indexOf(this.opcionActiva.toLowerCase()) >= 0
          );
        case "id":
          return this.opciones.filter(
            option => option.id.toString().indexOf(this.opcionActiva) >= 0
          );
      }
      return [];
    }
  },
  watch: {
    filtroActivo: function(val) {
      this.opcionActiva = "";
      switch (val) {
        case "todas":
          this.sinFiltro = true;
          this.$store.dispatch("disposicion/clearDisposicionesFiltradas");
          break;
        case "credito_status_id":
          this.sinFiltro = false;
          this.field = "nombre";
          this.opciones = this.getCreditoStatus;
          break;
        case "ejecutivo_id":
          this.sinFiltro = false;
          this.field = "alias";
          this.opciones = this.getEjecutivos;
          break;
        case "acreditado_id":
          this.sinFiltro = false;
          this.field = "alias";
          this.opciones = this.getClientes;
          break;
        case "id":
          this.sinFiltro = false;
          this.opciones = this.disposiciones;
          this.field = "id";
          break;
        case "productos_financieros_id":
          this.sinFiltro = false;
          this.field = "alias";
          this.opciones = this.getProductosFinancieros;
          break;
      }
    },
    activeTab: function() {
      this.filtroActivo = null;
      this.tagsFiltros = [];
      this.opcionActiva = "";
    },
    pagina: function(pagina) {
      this.paginacionDisposiciones(pagina);
    }
  },
  mounted() {
    if (this.getEjecutivos == null) {
      this.$store.dispatch("catalogos/getEjecutivos");
    }
    if (this.getCreditoStatus == null) {
      this.$store.dispatch(
        "catalogos/getCreditoStatus",
        this.lineaId ? null : 0
      );
    }
    if (this.getClientes == null) {
      this.$store.dispatch("catalogos/getClientes");
    }
    if (this.getProductosFinancieros.length == 0) {
      this.$store.dispatch("catalogos/getProductosFinancieros");
    }
  }
};
</script>
